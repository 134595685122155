import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
	APP_INITIALIZER,
	ApplicationConfig,
	EnvironmentProviders,
	ErrorHandler,
	isDevMode,
	Provider,
	provideZoneChangeDetection,
} from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	PreloadAllModules,
	provideRouter,
	Router,
	RouteReuseStrategy,
	withPreloading,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { AlertService } from '@context/frontend/alert';
import {
	API_URL,
	ORGANIZATION_ID,
	USER_ID,
} from '@context/frontend/api-client';
import { AppRouteReuseStrategy } from '@context/frontend/navigation';
import { authBearerInterceptor } from '@context/frontend/user';
import { FirebaseConfig } from '@context/shared/types/common';
import { provideTransloco, TranslocoService } from '@jsverse/transloco';
import * as sentry from '@sentry/angular';
import {
	TUI_BUTTON_OPTIONS,
	tuiScrollbarOptionsProvider,
} from '@taiga-ui/core';
import { NG_EVENT_PLUGINS } from '@taiga-ui/event-plugins';
import { provideQuillConfig } from 'ngx-quill/config';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { TranslocoHttpLoader } from './transloco-loader';

type Providers = (Provider | EnvironmentProviders)[];

// We only want to add sentry if we are in production
const SentryProviders: Providers = environment.production
	? [
			{
				provide: ErrorHandler,
				useValue: sentry.createErrorHandler({ showDialog: false }),
			},
			{
				provide: sentry.TraceService,
				deps: [Router],
			},
			{
				provide: APP_INITIALIZER,
				useFactory: () => () => {
					//
				},
				deps: [sentry.TraceService],
				multi: true,
			},
		]
	: [];

const FirebaseProviders: Providers = environment.firebase
	? [
			provideFirebaseApp(() =>
				initializeApp(environment.firebase as FirebaseConfig),
			),
			provideFirestore(() => getFirestore()),
			provideAnalytics(() => getAnalytics()),
			provideStorage(() => getStorage()),
			provideAuth(() => getAuth()),
		]
	: [];

const TaigaProviders: Providers = [
	{
		provide: TUI_BUTTON_OPTIONS,
		useValue: {
			appearance: 'primary',
			size: 'm',
		},
	},
];

export const appConfig: ApplicationConfig = {
	providers: [
		provideAnimations(),
		NG_EVENT_PLUGINS,
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(appRoutes, withPreloading(PreloadAllModules)),
		{ provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
		...SentryProviders,
		...FirebaseProviders,
		...TaigaProviders,
		AlertService,
		tuiScrollbarOptionsProvider({ mode: 'hover' }),
		provideHttpClient(withInterceptors([authBearerInterceptor])),
		provideTransloco({
			config: {
				availableLangs: [
					'en',
					// 'es'
				],
				defaultLang: 'en',
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				prodMode: !isDevMode(),
			},
			loader: TranslocoHttpLoader,
		}),
		{ provide: API_URL, useValue: environment.apiUrl },
		{
			provide: ORGANIZATION_ID,
			useValue: new BehaviorSubject<string | null>(null),
		},
		{
			provide: USER_ID,
			useValue: new BehaviorSubject<string | null>(null),
		},
		provideServiceWorker('ngsw-worker.js', {
			enabled: !isDevMode(),
			registrationStrategy: 'registerWhenStable:30000',
		}),
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslocoService],
			multi: true,
		},
		provideQuillConfig({
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'strike'],
					[{ list: 'ordered' }, { list: 'bullet' }],
					['link'],
					['clean'],
				],
			},
		}),
	],
};

/**
 * We want to load in the translations before any pages load to avoid
 * missing translation issues
 */
export function appInitializerFactory(transloco: TranslocoService) {
	return () => {
		transloco.setDefaultLang('en');
		return firstValueFrom(transloco.selectTranslation('en'));
	};
}
