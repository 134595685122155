import { Environment } from '@context/shared/types/common';

export const environment: Environment = {
	app: 'web',
	production: false,
	apiUrl: 'https://us-central1-dev-papyrus.cloudfunctions.net/api',
	firebase: {
		apiKey: 'AIzaSyCVjinrxOw32GUmxOysT5PlPH3BWWABp3U',
		authDomain: 'dev-papyrus.firebaseapp.com',
		projectId: 'dev-papyrus',
		storageBucket: 'dev-papyrus.appspot.com',
		messagingSenderId: '621818219700',
		appId: '1:621818219700:web:1f37767078aa2145791be1',
		measurementId: 'G-RP6BP6WFZN',
	},
};
